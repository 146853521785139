








































































































































































































































































































































































































































































@import "../../assets/css/exper/experiment";

.modeFor {
  margin-bottom: 10px;
}

.addInstru {
  width: 150px;
  height: 32px;
  border: 1px solid #BBBBBB;
  text-align: left;
  color: #999999;

  &:hover, &:focus {
    background-color: transparent;
    color: #999999;
  }
}

.delIns {
  border-color: #BBBBBB;
  padding: 8px 10px;
  margin-left: 10px;

  &:hover {
    border-color: #BBBBBB;
  }
}
